import { React, useEffect, useState } from "react";
import { Col, Card, Typography, Row, Input, Space, Select } from "antd";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus-icon.svg";
// import { ReactComponent as UploadImage } from "../../../assets/icons/uploadImage.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as RupeeIcon } from "../../../assets/seller/icons/ruppe_icon.svg";
import { ReactComponent as CreditIcon } from "../../../assets/seller/icons/mobcredit_icon.svg";
import { ReactComponent as ElectricityIcon } from "../../../assets/seller/icons/electricity.svg";
import { ReactComponent as InfoIcon } from "../../../assets/seller/icons/Icon.svg";
import { billingAddressSelector } from "../../../redux/slices/createQuote/selector";
import "./Payments.css";
import { useSelector } from "react-redux";
function Payments({
  paymentmodeItems,
  setpaymentmodeItems,
  paymentReceived,
  setpaymentReceived,
  userData,
  editCondition,
  isCreditOrder,
  dueAmount,
  totalAmount,
  OrderStatus,
}) {
  // console.log("🚀 ~ paymentReceived:", paymentmodeItems[0]?.dropdownValue)
  const bmp_credit_enable =
    localStorage.getItem("bmp_credit_enable") === "true";
  const [errorMessages, setErrorMessages] = useState([]);
  const creditBalance = userData?.mobCredit?.balance || 0.0;
  const billing_address = useSelector(billingAddressSelector);

  const StorePaymentModes = [
    //   {
    //     mobCredit: bmp_credit_enable && {
    //     value: "mobCredit",
    //     label: (
    //       <div>
    //         <span style={{ display: "flex", alignItems: "center" }}>
    //           {" "}
    //           <CreditIcon
    //             className="creditdropdown-icon"
    //             style={{ marginRight: 4 }}
    //           />{" "}
    //           mobCREDIT
    //         </span>{" "}
    //         <span className="creditdropdown">
    //           <span
    //             className={`${
    //               userData?.mobCredit?.account_status === "ACTIVE"
    //                 ? "text-credit-active"
    //                 : "text-credit-inactive"
    //             }`}
    //           >
    //             Available: ₹ {creditBalance}
    //           </span>
    //         </span>
    //       </div>
    //     ),
    //     disabled: userData?.mobCredit?.account_status !== "ACTIVE",
    //   }
    // },
    {
      value: "Cash",
      label: (
        <span style={{ display: "flex", alignItems: "center" }}>
          <RupeeIcon
            className="creditdropdown-icon"
            style={{ marginRight: 4 }}
          />{" "}
          Cash
        </span>
      ),
    },
    {
      value: "UPI",
      label: (
        <span style={{ display: "flex", alignItems: "center" }}>
          <RupeeIcon
            className="creditdropdown-icon"
            style={{ marginRight: 4 }}
          />{" "}
          UPI
        </span>
      ),
    },
    {
      value: "Card",
      label: (
        <span style={{ display: "flex", alignItems: "center" }}>
          <RupeeIcon
            className="creditdropdown-icon"
            style={{ marginRight: 4 }}
          />{" "}
          Card
        </span>
      ),
    },
    {
      value: "Bank transfer",
      label: (
        <span style={{ display: "flex", alignItems: "center" }}>
          <RupeeIcon
            className="creditdropdown-icon"
            style={{ marginRight: 4 }}
          />{" "}
          Bank transfer
        </span>
      ),
    },
    {
      value: "others",
      label: (
        <span style={{ display: "flex", alignItems: "center" }}>
          <RupeeIcon
            className="creditdropdown-icon"
            style={{ marginRight: 4 }}
          />{" "}
          Others
        </span>
      ),
    },
    // {
    //   value: "mobCredit",
    //   label: (
    //     <span>
    //       <CreditIcon style={{ marginRight: 4 }} /> mobCREDIT
    //     </span>
    //   ),
    // },
  ];
  if (bmp_credit_enable) {
    StorePaymentModes.unshift({
      value: "mobCredit",
      label: (
        <div>
          <Typography.Text style={{ display: "flex", alignItems: "center" }}>
            <CreditIcon
              className="creditdropdown-icon"
              style={{ marginRight: 4 }}
            />{" "}
            mobCREDIT
          </Typography.Text>{" "}
          <Typography.Text className="creditdropdown">
            <span
              className={`${
                userData?.mobCredit?.account_status === "ACTIVE"
                  ? "text-credit-active"
                  : "text-credit-inactive"
              }`}
            >
              Available: ₹ {creditBalance}
            </span>
            {!billing_address?.mob_credit &&
              userData?.mobCredit?.account_status === "ACTIVE" && (
                <>
                  <br />
                  <Typography.Text
                    className="text-danger"
                    style={{ fontSize: "12px" }}
                  >
                    Select mobCREDIT tagged <br /> billing address to proceed
                  </Typography.Text>
                </>
              )}
          </Typography.Text>
        </div>
      ),
      disabled:
        userData?.mobCredit?.account_status !== "ACTIVE" ||
        !billing_address?.mob_credit ||
        paymentmodeItems.some(item => item.dropdownValue === "mobCredit")
    });
  }
  useEffect(() => {
    const totalAmount = paymentmodeItems.reduce((acc, item) => {
      // console.log("🚀 ~ useEffect ~ item:", item)

      if (
        item.dropdownValue !== null &&
        item.dropdownValue !== "" &&
        item.value !== null &&
        item.value !== ""
      ) {
        return acc + Number(item.value);
      }
      return acc;
    }, 0);

    setpaymentReceived(totalAmount);
  }, [setpaymentReceived, paymentmodeItems]);
  const handleAddItem = () => {
    setpaymentmodeItems([
      ...paymentmodeItems,
      { value: "", dropdownValue: "" },
    ]);
  };

  const handleRemoveItem = (index) => {
    setpaymentmodeItems(paymentmodeItems.filter((item, i) => i !== index));
  };

  // const handleTextChange = (index, event) => {
  //   const value = event.target.value;
  //   setpaymentmodeItems(
  //     paymentmodeItems.map((item, i) => {
  //       if (i === index) {
  //         return { ...item, value };
  //       }
  //       return item;
  //     })
  //   );
  // };
  const handleTextChange = (index, event) => {
    const value = event.target.value;
    const selectedItem = paymentmodeItems[index];

    if (selectedItem.dropdownValue === "mobCredit") {
      if (value > totalAmount) {
        setErrorMessages((prevErrorMessages) => [
          ...prevErrorMessages.slice(0, index),
          `mobCREDIT amount can’t be more than total amount.`,
          ...prevErrorMessages.slice(index + 1),
        ]);
        setpaymentmodeItems(
          paymentmodeItems.map((item, i) => {
            if (i === index) {
              return { ...item, value };
            }
            return item;
          })
        );
      } else if (value < 100) {
        setErrorMessages((prevErrorMessages) => [
          ...prevErrorMessages.slice(0, index),
          "Amount should be greater than ₹100",
          ...prevErrorMessages.slice(index + 1),
        ]);
        setpaymentmodeItems(
          paymentmodeItems.map((item, i) => {
            if (i === index) {
              return { ...item, value };
            }
            return item;
          })
        );
      } else if (value > creditBalance) {
        setErrorMessages((prevErrorMessages) => [
          ...prevErrorMessages.slice(0, index),
          `Avaiable mobCREDIT balance is ₹ ${creditBalance}`,
          ...prevErrorMessages.slice(index + 1),
        ]);
      } else {
        setpaymentmodeItems(
          paymentmodeItems.map((item, i) => {
            if (i === index) {
              return { ...item, value };
            }
            return item;
          })
        );
        setErrorMessages((prevErrorMessages) => [
          ...prevErrorMessages.slice(0, index),
          null,
          ...prevErrorMessages.slice(index + 1),
        ]);
      }
    } else {
      setpaymentmodeItems(
        paymentmodeItems.map((item, i) => {
          if (i === index) {
            return { ...item, value };
          }
          return item;
        })
      );
    }
  };

  const handleDropdownChange = (index, value) => {
    setpaymentmodeItems(
      paymentmodeItems.map((item, i) => {
        if (i === index) {
          return { ...item, dropdownValue: value };
        }
        return item;
      })
    );
  };

  const titleValue =
    dueAmount > 0 && editCondition && isCreditOrder ? (
      <span>
        <InfoIcon /> mobCREDIT amount increase not possible
      </span>
    ) : (
      ""
    );

  return (
    <>
      <Col
        className="paymentsummary"
        style={{ display: "flex", justifyContent: "right" }}
      >
        <Card
          style={{
            width: "50%",
            marginTop: "24px",
            boxShadow: " 0 1px 4px 0 rgba(0, 0, 0, 0.16)",
            border: "solid 1px #eaeaea",
            background: "#f3f2ef",
            padding: "10px",
          }}
          className="card-right-payment"
          title={titleValue}
        >
          <Col
            className="mb-4"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography.Text className="text-font">
              Payment received
            </Typography.Text>
            <Typography.Text>
              <span className="text-font">
                {" "}
                ₹ {(paymentReceived && Number(paymentReceived).toFixed(2)) ||
                  0}{" "}
              </span>
            </Typography.Text>
          </Col>
          <Typography>
            <Col
              style={{
                borderRadius: "20px",
                backgroundColor: "#f1f1f2",
              }}
            ></Col>
            {paymentmodeItems.map((item, index) => (
              <>
                <Row key={index} className="mb-2">
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Space.Compact
                      style={{ width: "100%" }}
                      className={
                        errorMessages[index] ? "cls-outline-error" : ""
                      }
                    >
                      <Select
                        className="paymentmode"
                        // defaultValue="UPI"
                        value={item?.dropdownValue || "Select"}
                        placeholder="Select"
                        options={StorePaymentModes}
                        onChange={(value) => handleDropdownChange(index, value)}
                        disabled={item?.disabled}
                      />
                      <Input
                        inputMode="numeric"
                        maxLength={8}
                        value={item?.value}
                        placeholder="0"
                        className="input-style input-style-width"
                        onKeyDown={(e) => {
                          if (
                            (e.key < "0" || e.key > "9") &&
                            e.key !== "Backspace" &&
                            e.key !== "ArrowLeft" &&
                            e.key !== "ArrowRight" &&
                            e.key !== "Delete" &&
                            e.key !== "Tab" &&
                            e.key !== "." &&
                            !(e.ctrlKey && (e.key === "c" || e.key === "v")) // allow Ctrl+C and Ctrl+V
                          ) {
                            e.preventDefault();
                          }
                        }}
                        prefix={"₹"}
                        onChange={(event) => handleTextChange(index, event)}
                        disabled={item?.disabled}
                      />
                    </Space.Compact>

                    <Typography
                      style={{ marginLeft: "10px" }}
                      onClick={() => handleRemoveItem(index)}
                      disabled={item?.disabled}
                    >
                      <DeleteIcon />{" "}
                    </Typography>
                  </Col>
                </Row>
                {errorMessages[index] && (
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Typography
                        style={{
                          color: "#f0483e",
                          fontWeight: 500,
                          fontSize: 12,
                        }}
                      >
                        {errorMessages[index]}
                      </Typography>
                    </Col>
                  </Row>
                )}
              </>
            ))}
            <Row>
              <Col>
                <Typography
                  style={{ color: "#015fe5", cursor: "pointer" }}
                  onClick={handleAddItem}
                >
                  <PlusIcon /> Add payment
                </Typography>
              </Col>
            </Row>
          </Typography>
        </Card>
      </Col>
      {isCreditOrder && editCondition && dueAmount < 0 && (
        <Row>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}></Col>
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ display: "flex", justifyContent: "right" }}
          >
            <Card
              className="static-text-credit card-right-payment"
              style={{
                width: "100%",
                marginTop: "24px",
                boxShadow: " 0 1px 4px 0 rgba(0, 0, 0, 0.16)",
                border: "solid 1px #eaeaea",
                background: "#f3f2ef",
                padding: "10px",
              }}
            >
              {OrderStatus === "Delivered" ? (
                <Row gutter={[16, 16]} className="text-font">
                  <Typography.Text>
                    <ElectricityIcon className="mr-4" /> Post delivery marked
                    order EDIT
                  </Typography.Text>
                  <Col className="mt-4">
                    <Typography.Text>
                      Order amount increase <br />
                      1. mobCREDIT: amount increase/ edit is NOT possible
                      <br />
                      2. Collect additional amount separately
                      <br />
                      <span style={{ fontStyle: "italic" }}>
                        TIP: Recommended to create new order for new items
                      </span>
                    </Typography.Text>
                  </Col>
                  <Col className="mt-4">
                    <Typography.Text>
                      Order amount decrease
                      <br />
                      1. mobCREDIT: amount decrease/ edit is NOT possible
                      <br />
                      2. Refund the excess amount to client directly
                      <br />
                      <span style={{ fontStyle: "italic" }}>
                        TIP: Excess amount will be added to ‘bills receivables’
                        if not shown here
                      </span>
                    </Typography.Text>
                  </Col>
                </Row>
              ) : (
                <Row gutter={[16, 16]} className="text-font">
                  <Typography.Text>
                    <ElectricityIcon className="mr-4" /> Post conversion order
                    EDIT
                  </Typography.Text>
                  <Col className="">
                    <Typography.Text style={{ fontWeight: "normal" }}>
                      Edits can be made until the order have been marked
                      DELIVERED <br />
                      1. Increase in mobCREDIT amount is NOT possible <br />
                      2. Decrease in mobCREDIT amount is possible{" "}
                      <span style={{ fontStyle: "italic" }}>
                        (System will auto adjust client’s mobCREDIT amount once
                        marked delivered)
                      </span>
                    </Typography.Text>
                  </Col>
                  <Col className="mt-2">
                    <Typography.Text style={{ fontWeight: "normal" }}>
                      NOTE: Any amount increase can be collected by other
                      payment methods OR GENERATE A NEW ORDER
                    </Typography.Text>
                  </Col>
                </Row>
              )}
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}

export default Payments;
