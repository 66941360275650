import {
  Avatar,
  Button,
  Col,
  Skeleton,
  Typography,
  Tag,
  Row,
  Card,
  Divider,
  Modal,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./SellerViewDetails.css";
// import {
//   // CheckCircleFilled,
//   // CloseCircleFilled,
//   CloseOutlined,
//   CheckOutlined,
// } from "@ant-design/icons";
// import DropdownComponent from "../../../../modules/Request/RFQ/DropdownComponent";
// import { EyeOutlined } from "@ant-design/icons";
import { ReactComponent as MobCrediticon } from "../../../../../assets/seller/icons/Mobcreditlogo.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/icons/edit-2.svg";
import { ReactComponent as FileIcon } from "../../../../../assets/icons/fileI.svg";
import { ReactComponent as NotesIconIcon } from "../../../../../assets/seller/icons/notesicon.svg";
import { ReactComponent as WarningIcon } from "../../../../../assets/icons/warning.svg";
//   import StatusDropDown from "../SellerOrderItems/StatusDropDown";
import { useNavigate, useParams, Link } from "react-router-dom";
//   import NoVehicleAssigned from "./NoVehicleAssigned/NoVehicleAssigned";
import DeliveryTable from "./SellerDeliveryTable/SellerDeliveryTable";
import CustomerNote from "./SellerCustomerNote/SellerCustomerNote";
import dayjs from "dayjs";
import AddDirectPaymentModal from "../../../../../components/SellerComponents/SellerPayable/AddPaymentModal/AddDirectPaymentModal";
//   import AddVehicleModal from "./AddVehicleModal/AddVehicleModal";
//   import CustomProfileSection from "../../../../components/CustomContainer/CustomProFileSection/CustomProfileSection";
//   import SelectedVehicleTable from "./SelectedVehicleTable/SelectedVehicleTable";

// import { UpArrow } from "../../../../components/Icons/UpArrow";
// import { DownArrow } from "../../../../components/Icons/DownArrow";
import { ReactComponent as DefaultIcon } from "../../../../../assets/icons/default.svg";
import {
  useAddVehicleMutation,
  useDeleteSuborderVehicleMutation,
  useLazyGetVehiclesDetailQuery,
  useLazyGetVendorAddressQuery,
  useUpdateVehiclesDetailMutation,
} from "../../../../../apis/ManageOrders";

import {
  useLazySellerGetSubOrderDetailsQuery,
  useUpdateOrderStatusMutation,
  useUpdateVehcileRequestStatusMutation,
} from "../../../../../apis/Seller/SellerOrdersApi";
import { useDispatch, useSelector } from "react-redux";
import {
  editSubOrderDetailSelector,
  // vehicleDetailSelector,
  // vehicleDetailSelector,
} from "../../../../../redux/slices/manageOrders/selector";
import { getEditSubOrderDetail } from "../../../../../redux/slices/manageOrders/action";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../../NotificationToast/NotificationToast";

import { useAddNewPaymentMutation } from "../../../../../apis/Seller/SellerPayablesAPI";
import ManageDeleteModal from "../../../../../components/Modal/DeleteModal/ManageDeleteModal/ManageDeleteModal";
import { baseUrl } from "../../../../../commonUtils/commonUtils";
import { handlePdfDownload } from "../../../../../commonFuntions/CommonUtilFunctions";

import SellerChooseVehicleModal from "../SellerModals/SellerChooseVehicleModal/SellerChooseVehicleModal";
import SellerConfirmationModal from "../SellerOrderModals/SellerConfirmationModal/SellerConfirmationModal";
import { SellerCancelOrderModal } from "../SellerOrderModals/SellerCancelOrderModal/SellerCancelOrderModal";
import SelectedVehicleTable from "../../../../ManageOrders/OrderDetails/ViewDetails/SelectedVehicleTable/SelectedVehicleTable";
import { SellerRejectOrderModal } from "../SellerOrderModals/SellerCancelOrderModal/SellerRejectOrderModal";

const { Text } = Typography;

const SellerViewDetails = () => {
  const { id } = useParams();

  const bmp_id = localStorage.getItem("bmp_id");
  // eslint-disable-next-line
  const [addVehicleModalOpen, setAddVehicleModalOpen] = useState(false);
  // eslint-disable-next-line
  const [updateVehicleModalOpen, setUpdateVehicleModalOpen] = useState(false);
  const editSubOrderData = useSelector(editSubOrderDetailSelector);
  // console.log("🚀 ~ SellerViewDetails ~ editSubOrderData:", editSubOrderData)
  const [deliveryStatus, setDeliveryStatus] = useState("");
  // eslint-disable-next-line
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [ChooseVehicleModalOpen, setChooseVehicleModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  // eslint-disable-next-line
  const [productTableData, setProductTableData] = useState({});
  const [vendorAddressData, setVendorAddressData] = useState([]);
  const [ConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  // eslint-disable-next-line
  const [vehicleUpdateData, setVehicleUpdateData] = useState({});
  // eslint-disable-next-line
  const [datePikerEmpty, setDatePickerEmpty] = useState(false);
  const [getVendorAddressApi, { isFetching }] = useLazyGetVendorAddressQuery();
  // eslint-disable-next-line
  const [validationErrors, setValidationErrors] = useState({});
  const [pickupAddressValue, setPickupAddressValue] = useState({});
  // eslint-disable-next-line
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [DeliverydateValue, setDeliveryDateValue] = useState("");
  const [selectedTime, setSelectedTime] = useState("Early morning 6am - 9am");
  const [editOrderVal, seteditOrderVal] = useState({});
  const [summaryModalOpen, setSummaryModalOpen] = useState(false);
  const handleCancel = () => {
    setSummaryModalOpen(false);
  };
  const paymentsummarycolumns = [
    // {
    //   title: "Payment date",
    //   dataIndex: "payment_date",
    //   width: "150px",
    //   render: (params, record) => {
    //     console.log("🚀 ~ record:", record)
    //     return (
    //       <>

    //         <Col>{onlyDateFormat(params)}
    //         {/* {(record?.added_by?.name &&
    //         <Tooltip title={`${record?.added_by?.name}, ${record?.added_by?.user}`} color={"#fff"} key={"#2973f0"}>
    //          {" "}
    //          <InfoIcon style={{marginTop:"10px"}} />

    //         </Tooltip>
    //         )} */}
    //         </Col>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Amount",
      dataIndex: "amount",
      // width: "100px",
      align: "left",
      render: (params, record) => {
        return (
          <Col style={{ color: params < 0 ? "red" : "green" }}>
            {(params || 0).toFixed(2)}
          </Col>
        );
      },
    },
    {
      title: "Payment type",
      dataIndex: "payment_type",
      // width: "150px",
    },
    {
      title: "Payment mode",
      dataIndex: "payment_mode",
      // width: "150px",
    },
    // {
    //   title: "Reference no",
    //   dataIndex: "reference_number",
    //   width: "150px",
    // },
    // {
    //   title: "Payment paid",
    //   dataIndex: "is_paid",
    //   width: "150px",
    //   render: (params, record) => {
    //     return (
    //       <>
    //         <Col style={{ display: "flex", justifyContent: "center" }}>
    //           {params ? (
    //             <CheckOutlined style={{ color: "green" }} />
    //           ) : (
    //             <CloseOutlined style={{ color: "red" }} />
    //           )}
    //         </Col>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Payment Link",
    //   dataIndex: "payment_link",
    //   width: "150px",
    //   render: (params) => {
    //     return (
    //       params && (
    //         <Tooltip title="Copy url" color={"#fff"} key={"#2973f0"}>
    //           {" "}
    //           <Col
    //             className="link-wrapperI"
    //             onClick={() => copyLinkToClipboardRow(params)}
    //           >
    //             <FileIcon />
    //             <Text>Copy link</Text>
    //           </Col>
    //         </Tooltip>
    //       )
    //     );
    //   },
    // },
  ];
  const [
    orderNewPaymentApi,
    // eslint-disable-next-line
    { isSuccess: successnewOrderPayment, isLoading: isPaymentFetching },
  ] = useAddNewPaymentMutation();
  const [
    getSubOrderDetailApi,
    { data: editSubData, isFetching: isFetchingEditSubData },
  ] = useLazySellerGetSubOrderDetailsQuery();

  // eslint-disable-next-line
  const [checkboxState, setCheckboxState] = useState({
    email: false,
    phone_number: false,
  });
  // eslint-disable-next-line
  const [getVehiclesDetailApi, { data: vehicleData }] =
    useLazyGetVehiclesDetailQuery();
  // eslint-disable-next-line
  const [addVehicleApi, { isSuccess: successAddVehicleApi }] =
    useAddVehicleMutation();
  // eslint-disable-next-line
  const [updateVehicleApi] = useUpdateVehiclesDetailMutation();
  // eslint-disable-next-line
  const [titleText, settitleText] = useState("Add Payment");
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [editPayment, setEditPayment] = useState({
    state: false,
    check: true,
  });
  // eslint-disable-next-line
  const handleStatus = () => {
    const record = {
      order: {
        order_id: editSubData?.data?.store_order_id,
        suborder_id: editSubData?.data?.suborder_id,
      },
      total: editSubData?.data?.total,
      pending_amount: totalAmountDue,
    };
    seteditOrderVal(record);
    setOpenUploadModal(true);
  };
  const [paymentMode, setPaymentMode] = useState();
  const [inputDate, setInputDate] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [addVehicleFormData, setAddVehicleFormData] = useState([
    {
      id: 1,
      vechicle_type: "SELECT VEHICLE",
      vechicle_type_Id: "SELECT VEHICLE",
      quantity: 1,
      vechicle_image: "",
      vechicle_weight: "",
      vechicle_storage: "",
      vehicleVisible: "",
    },
  ]);
  const [
    deleteSuborderVehicleApi,
    { isSuccess: successSuborderVehicleDelete },
  ] = useDeleteSuborderVehicleMutation();

  const handleOk = () => {
    setOpenUploadModal(false);
    setPaymentMode("");
    setInputDate("");
    // setDatePickerEmpty(false);
    // setFormData({});
  };

  const [
    updateOrderStatusApi,
    { isLoading: updateOrderStatusLoading },
    //  { isSuccess: successUpdateOrderStatusApi }
  ] = useUpdateOrderStatusMutation();

  const [
    updateOrderVehicleRequestApi,
    { isLoading: updateOrderVehicleRequestLoading },
    //  { isSuccess: successUpdateOrderStatusApi }
  ] = useUpdateVehcileRequestStatusMutation();
  // const vehicleDetailData = useSelector(vehicleDetailSelector);

  const handleOpenCancelModal = () => {
    setOpenCancelModal(true);
  };
  const handleOpenRejectModal = () => {
    setOpenRejectModal(true);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const handleDeleteModalOpen = () => {
  //   setIsDeleteModalOpen(true);
  // };
  const handleDeleteModalCancel = () => {
    setIsDeleteModalOpen(false);
  };
  // const showDrawer = () => {
  //   setDrawerVisible(true);
  // };
  const handleChooseVehicle = async () => {
    const response = await getVendorAddressApi({ bmp_id: bmp_id });

    setVendorAddressData(response?.data);

    setChooseVehicleModalOpen(true);
  };
  // const _onRowClick = (data) => {
  //   setProductTableData(data);
  // };
  // const handlePdfPreview = (pdfURL) => {
  //   const pdfUrl = ${baseUrl}${pdfURL};
  //   window.open(pdfUrl, "_blank");
  //   // fetch(pdfUrl)
  //   //   .then((response) => response.blob())
  //   //   .then((blob) => {
  //   //     const url = window.URL.createObjectURL(new Blob([blob]));
  //   //     const link = document.createElement("a");
  //   //     link.href = url;
  //   //     link.download = "Customer-Quotation.pdf";
  //   //     document.body.appendChild(link);
  //   //     link.click();
  //   //     link.parentNode.removeChild(link);
  //   //     window.URL.revokeObjectURL(url);
  //   //   })
  //   //   .catch((error) => {
  //   //     console.error("Error downloading PDF: ", error);
  //   //     // showErrorToast("Error downloading PDF!", error);
  //   //   });
  // };
  const onDelete = async () => {
    const params = { vehicle_id: productTableData?.id };
    const response = await deleteSuborderVehicleApi(params);
    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      setIsDeleteModalOpen(false);
      // libraryTableApi();
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };
  // const onEdit = (data) => {
  //   setVehicleUpdateData(data);
  //   setUpdateVehicleModalOpen(true);
  // };
  const totalPriceAfterTax = editSubOrderData?.products?.reduce(
    (acc, current) => acc + Number(current.price_after_tax || 0),
    0
  );
  let discountAmount = 0;
  if (editSubOrderData?.discount_in === "percentage") {
    discountAmount = (totalPriceAfterTax * editSubOrderData?.discount) / 100;
  } else {
    discountAmount = editSubOrderData?.discount;
  }
  // eslint-disable-next-line
  const finalTotal = totalPriceAfterTax - discountAmount;
  // eslint-disable-next-line
  const totalQuantity = editSubOrderData?.data?.items.reduce(
    (acc, item) => acc + item.quantity,
    0
  );

  const totalAmountPaid = (editSubOrderData?.payments || []).reduce(
    (acc, item) => acc + item.amount,
    0
  );

  const totalAmountDue = (
    Number(editSubOrderData?.total) - Number(totalAmountPaid)
  ).toFixed(2);

  const mobCreditAmount = editSubOrderData?.payments?.reduce((acc, payment) => {
    if (payment.payment_mode === "mobCredit") {
      return acc + payment.amount;
    }
    return acc;
  }, 0);
  const RefundAmount= Number(mobCreditAmount)-Number(totalAmountPaid);
  // eslint-disable-next-line
  const columns = [
    {
      title: "Vehicle type",
      dataIndex: "vehicle_type",
      key: "vehicle_type",

      render: (params, record) => {
        return (
          <>
            <Col
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "left",
                padding: "16px",
              }}
            >
              <Col>
                <Avatar
                  style={{
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    alignItems: "left",
                    justifyContent: "center",
                    backgroundColor: "#f1f1f2",
                  }}
                  shape="square"
                  src={`${baseUrl}${record?.vehicle_info?.vechicle_image}`}
                  alt="Avatar"
                  icon={<DefaultIcon width={20} height={20} />}
                />
              </Col>
              <Col>
                <Col style={{ fontWeight: 500, textAlign: "left" }}>
                  {record?.vehicle_info?.vechicle_type || "No value"}
                </Col>
                <Col style={{ display: "flex", gap: "1rem" }}>
                  <Text style={{ color: "#6c7c8c" }}>{`Weight: ${
                    record?.vehicle_info?.vechicle_weight || "No value"
                  }`}</Text>
                  <Text style={{ color: "#6c7c8c" }}>{`storage: ${
                    record?.vehicle_info?.vechicle_storage || "No value"
                  }`}</Text>
                </Col>
              </Col>
            </Col>
          </>
        );
      },
    },
    {
      title: "Unit",
      dataIndex: "vehicle_type",
      key: "Unit",
      align: "right",
      render: (params, record) => {
        return (
          <Col style={{ fontWeight: 500, textAlign: "right" }}>
            {record?.count || "0"}
          </Col>
        );
      },
    },
    // {
    //   title: "Vehicle number",
    //   dataIndex: "vechicle_number",
    //   key: "vechicle_number",
    // },
    // {
    //   title: "Assigned time",
    //   dataIndex: "assigned_time",
    //   key: "assigned_time",
    // },
    // {
    //   title: "Driver name",
    //   dataIndex: "driver_name",
    //   key: "driver_name",
    // },
    // {
    //   title: "Driver mobile",
    //   dataIndex: "driver_phone_number",
    //   key: "driver_phone_number",
    // },
    // {
    //   title: "Logistic partner",
    //   dataIndex: "logistic_partner",
    //   key: "logistic_partner",
    // },
  ];
  const columnsDeliveryBy = [
    {
      title: `${editSubOrderData?.item} Items`,
      // dataIndex: "products",
      render: (params, record) => {
        return (
          <>
            <Col className="deliveryBy-wrapper">
              <Col>
                <Avatar
                  size={88}
                  shape="square"
                  src={`${baseUrl}${params?.product_image}`}
                />
              </Col>
              <Col
                className="deliveryBy-items"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  maxWidth: "380px",
                }}
              >
                <Text style={{ fontSize: "16px", fontWeight: 500 }}>
                  {params?.product_name}
                </Text>
                {params?.mob_sku && (
                  <Text style={{ color: "#6c7c8c" }}>
                    MOBSKU: {params?.mob_sku}
                  </Text>
                )}
              </Col>
            </Col>
            {/* {deliveryStatus === "Delivered" && (
                <Col className="return-exchange-notify">
                  <Col
                    className="status-pointer"
                    style={{ backgroundColor: "#4fb589" }}
                  />
                  <Text className="delivery-status-notify">
                    Return/ Exchange window open till 12 Jan 2022
                  </Text>
                </Col>
              )} */}
          </>
        );
      },
    },
    {
      title: "Unit",
      align: "center",
      dataIndex: "quantity",
    },
    {
      title: "Amount",
      // dataIndex: "price_after_tax",
      align: "right",
      render: (params, record) => {
        return (
          <Col className="text-right" style={{ marginRight: "16px" }}>
            {/* {` ₹ ${Number(params).toFixed(2)}`} */}
            {` ₹ ${Number(
              (params?.price_after_tax || 0) * (params?.quantity || 0)
            ).toFixed(2)}`}
          </Col>
        );
      },
    },
  ];
  const handleBack = () => {
    navigate(-1);
  };
  const handlePrintChallan = () => {
    handlePdfDownload(editSubData?.data?.challan?.file);
  };

  const handleConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };

  const handleChangeStatus = async (status, index) => {
    const response = await updateOrderStatusApi({
      id: id,
      params: {
        status,
        notify_email: checkboxState?.email,
        notify_phone: checkboxState?.phone_number,
      },
    });
    if (response?.data?.status === true) {
      setDeliveryStatus(status);
      showSuccessToast(response?.data?.message);
      getSubOrderDetailApi(id);
      setConfirmationModalOpen(false);
      setChooseVehicleModalOpen(false);
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };
  const handleAcceptOrderStatus = async (status, index) => {
    const newData = [];

    addVehicleFormData.forEach((vehicle) => {
      const newVehicle = {
        sub_order: id,
        vehicle: vehicle.vechicle_type_Id,
        count: vehicle.quantity,
      };
      newData.push(newVehicle);
    });
    const response = await updateOrderVehicleRequestApi({
      id: id,
      params: {
        status,
        // notify_email: checkboxState?.email,
        // notify_phone: checkboxState?.phone_number,
        delivery_date: dayjs(DeliverydateValue).format("YYYY-MM-DD"),
        delivery_slot: selectedTime,
        sub_orders: newData,
      },
    });
    if (response?.data?.status === true) {
      setDeliveryStatus(status);
      showSuccessToast(response?.data?.message);
      getSubOrderDetailApi(id);
      setConfirmationModalOpen(false);
      setChooseVehicleModalOpen(false);
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };
  useEffect(() => {
    getSubOrderDetailApi(id);
    getVehiclesDetailApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successSuborderVehicleDelete, successAddVehicleApi]);
  useEffect(() => {
    if (editSubData?.status) {
      dispatch(getEditSubOrderDetail(editSubData?.data));
      setDeliveryStatus(editSubData?.data?.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSubData]);

  const handleCancelOrderModal = async (param) => {
    setOpenCancelModal(false);
    handleChangeStatus("Cancelled");
  };
  const handleCloseCancelModal = () => {
    setOpenCancelModal(false);
    setCheckboxState({ email: false, phone_number: false });
  };
  const handleRejectOrderModal = async (param) => {
    setOpenRejectModal(false);
    handleChangeStatus("Rejected by seller");
  };
  const handleRejectCancelModal = () => {
    setOpenRejectModal(false);
    setCheckboxState({ email: false, phone_number: false });
  };
  return (
    <Col className="view-detail-wrapper">
      <Col className="view-detail-status">
        <Col className="detail-activity">
          <Button
            className="view-detail-btn"
            icon={<ArrowLeftOutlined />}
            onClick={handleBack}
          >
            BACK TO ORDER
          </Button>
          {/* <ActivityContainer onClick={showDrawer}>
              <ClockIcon style={{ height: "20px", width: "20px" }} />
              <ActivityText level={5}>Activity log</ActivityText>
            </ActivityContainer> */}
        </Col>
        {isFetchingEditSubData ? (
          <>
            <Skeleton active paragraph={{ rows: 2 }} />
          </>
        ) : (
          <>
            <Col className="detail-invoice-wrapper">
              <Col className="comp-wrapper">
                {/* <DropdownComponent
                    // title={"Invoice"}
                    data={editSubData?.data?.invoices}
                    // handlePdfDownload={handlePdfPreview}
                    title={"Invoice"}
                    // data={element?.invoices}
                    // deleteSuborderFileApi={deleteSuborderFileApi}
                    status={deliveryStatus}
                    // proformaInvoice={editSubData?.data?.proforma_invoices}
                  />
                  <DropdownComponent
                    // title={"Invoice"}
                    data={editSubData?.data?.proforma_invoices}
                    // handlePdfDownload={handlePdfPreview}
                    title={"Proforma Invoice"}
                    // data={element?.invoices}
                    // deleteSuborderFileApi={deleteSuborderFileApi}
                    status={deliveryStatus}
                    // proformaInvoice={editSubData?.data?.proforma_invoices}
                  /> */}
                {/* <Text className="seller-view">View order in seller view</Text> */}
              </Col>
            </Col>
            {editSubData?.data?.is_store_order &&
              editSubData?.data?.isMobCredit && (
                <>
                  {deliveryStatus !== "Cancelled" ? (
                    <>
                      <Row className="Mobcredit-order-strip">
                        <Col xs={24} sm={12} md={12} lg={12} xl={20}>
                          <Text>This is a mobCREDIT order</Text>
                          <br />
                          <Text>
                            Please mark the order as delivered to get payment in
                            3 working days
                          </Text>
                        </Col>
                        <Col
                          xs={24}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={4}
                          style={{
                            justifyContent: "right",
                            display: "inline-flex",
                          }}
                        >
                          <MobCrediticon />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row className="card-alert-strip">
                        <Col xs={24} sm={12} md={12} lg={12} xl={20}>
                          <Text className="text-danger">
                            This is a cancelled mobCREDIT order
                          </Text>
                          <br />
                          <Text>
                            mobCREDIT amount will be auto refunded to customer’s
                            mobCREDIT account in 45 mins
                          </Text>
                        </Col>
                        <Col
                          xs={24}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={4}
                          style={{
                            justifyContent: "right",
                            display: "inline-flex",
                          }}
                        >
                          <MobCrediticon />
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}
            {!editSubData?.data?.is_store_order &&
              deliveryStatus !== "Waiting" &&
              deliveryStatus !== "Cancelled" &&
              deliveryStatus !== "Rejected by seller" &&
              deliveryStatus !== "Delivered" && (
                <Col className="shortly-alert-style">
                  <Text>
                    This order will be picked up shortly. Please keep the items
                    ready.
                  </Text>
                </Col>
              )}
            {deliveryStatus === "Rejected by seller" && (
              <Col className="shortly-alert-style-yellow">
                <Text>
                  This order has been cancelled. Reach out to support if
                  rejected by mistake
                </Text>
              </Col>
            )}
            {!editSubOrderData?.is_store_order &&  !editSubData?.data?.isMobCredit && deliveryStatus !== "Waiting" &&
              deliveryStatus !== "Cancelled" &&
              deliveryStatus !== "Rejected by seller" && (
                <Col className="section-deliverychallan">
                  <div className="assigned-container">
                    <p className="detail-heading">
                      {" "}
                      Please print the delivery challan for shipment <br />
                      <span className="detail-subheading">
                        Please print the delivery challan for shipment and hand
                        it to the delivery partner{" "}
                      </span>
                    </p>

                    <p>
                      <Button
                        className="detail-btn"
                        style={{
                          backgroundColor: "#01a685",
                          display: "flex",
                          alignItems: "center",
                          maxWidth: "360px",
                        }}
                        type="primary"
                        icon={<FileIcon />}
                        onClick={handlePrintChallan}
                      >
                        Print delivery challan (pdf)
                      </Button>
                    </p>
                  </div>
                </Col>
              )}
            <div className="assigned-container">
              <Col className="detail-status">
                <Text className="detail-heading">
                  {`Seller Order ID: ${editSubData?.data?.suborder_id}`}
                  <span>
                    <Tag
                      className={`status-tag-style ${
                        editSubData?.data?.status === "Waiting"
                          ? "status-waiting"
                          : editSubData?.data?.status === "Ready for Pickup"
                          ? "status-ready"
                          : editSubData?.data?.status === "Rejected by seller"
                          ? "status-rejected"
                          : editSubData?.data?.status === "Cancelled"
                          ? "status-cancelled"
                          : "status-default"
                      }`}
                    >
                      {editSubData?.data?.status}
                    </Tag>
                  </span>{" "}
                  <br />{" "}
                  <span className="detail-subheading">{`Order received: ${editSubData?.data?.created_at}`}</span>
                </Text>
              </Col>
              <Col className="text-right d-none d-md-block d-lg-block d-xl-block">
                <Text>
                  In case of any queries <br />
                  Call support:{" "}
                  <a href={`tel:+91 8660423608`}>+91 8660423608</a>
                </Text>
              </Col>
            </div>
          </>
        )}
        <Col className="details-btn-wrapper">
          <Col className="comp-wrapper">
            {deliveryStatus === "Delivered" && (
              <>
                {/* <ServiceComponent
                  openData={""}
                  handleOpen={handleOpenServiceRequestModal}
                  requestNumberCheck={true}
                  requestNumber={editSubOrderData?.service_requests?.length}
                />
                <ServiceRequestModal
                  title={"Service request"}
                  isModalOpen={openServiceModal}
                  setIsModalOpen={setOpenServiceModal}
                  saveText={"RAISE SERVICE REQUEST"}
                  handleCancel
                  data={editSubData?.data}
                  handleSave={handleSaveServiceRequest}
                  serviceRequestForm={serviceRequestForm}
                  setServiceRequestForm={setServiceRequestForm}
                  initialValues={initialServiceRequestData}
                  form={form}
                  loading={isLoadingServiceRequest || isFetchingServiceRequest}
                />
                <ViewServiceRequestModal
                  open={openViewServiceModal}
                  handleOk={handleCancelViewServiceRequest}
                  data={serviceRequestModalData?.data}
                  handleOpenDrawer={handleOpenServiceRequestDrawer}
                /> */}
                
              </>
            )}
            {editSubOrderData?.is_store_order &&  editSubData?.data?.isMobCredit && deliveryStatus !== "Waiting" &&
              deliveryStatus !== "Cancelled" &&
              deliveryStatus !== "Rejected by seller" && (
                <Col className="">
                      <Button
                        className="view-detail-btn"
                        style={{
                          backgroundColor: "#fff",
                          display: "flex",
                          alignItems: "center",
                          maxWidth: "360px",
                          color:"#0a243f"
                        }}
                        // type="primary"
                        icon={<NotesIconIcon />}
                        onClick={handlePrintChallan}
                      >
                        Print delivery challan (pdf)
                      </Button>
                  
                 
                </Col>
              )}
          </Col>
          <Col className="comp-wrapper">
            {deliveryStatus !== "Cancelled" && editSubOrderData?.is_store_order && (
              <Link
                to={`/Seller/StoreSales/update-order/${editSubData?.data?.store_order_id}`}
                target="_blank"
              >
                <Button className="view-detail-btn" icon={<EditIcon />}>
                  EDIT SALE
                </Button>
              </Link>
            )}
            
            {deliveryStatus === "Waiting" && (
              <Button
                className="detail-btn"
                style={{ color: "#bf2600", borderColor: "#bf2600" }}
                onClick={() => handleOpenRejectModal()}
              >
                REJECT
              </Button>
            )}
            {deliveryStatus !== "Cancelled" &&
              editSubOrderData?.is_store_order && (
                <Button
                  className="detail-btn"
                  style={{ color: "#bf2600", borderColor: "#bf2600" }}
                  onClick={() => handleOpenCancelModal()}
                >
                  CANCEL ORDER
                </Button>
              )}
            {deliveryStatus !== "Waiting" &&
              deliveryStatus !== "Delivered" &&
              deliveryStatus !== "Cancelled" &&
              deliveryStatus !== "Rejected by seller" && (
                <Button
                  className="detail-btn btn-primary"
                  // style={{ backgroundColor: "#0354a3" }}
                  type="primary"
                  onClick={handleConfirmationModal}
                >
                  {deliveryStatus === "Out for delivery"
                    ? "MARK DELIVERED"
                    : "ORDER SHIPPED"}
                </Button>
              )}
            {deliveryStatus === "Waiting" && (
              <Button
                className="detail-btn btn-primary"
                // style={{ backgroundColor: "#0354a3" }}
                type="primary"
                onClick={handleChooseVehicle}
                loading={isFetching}
              >
                ASSIGN VEHICLE TO ACCEPT
              </Button>
            )}
          </Col>
        </Col>
      </Col>
      {/* Service request */}
      {/* {(deliveryStatus === "Delivered" && editSubOrderData?.service_requests?.length>0) && (
        <>
          <Col>
            <Col style={{ display: "flex", gap: "15px", alignItems: "center" }}>
              <Text className="detail-heading">Service requests </Text>
              <Text>
                {editSubOrderData?.service_requests?.length || "0"} items
              </Text>
            </Col>
            <ServiceRequestList
              data={editSubOrderData?.service_requests}
              handleOpenView={handleOpenServiceRequestDrawer}
            />
          </Col>
        </>
      )} */}
      <Col>
        {editSubOrderData?.is_store_order && (
          <>
            <Text className="detail-heading">Order summary</Text>
            <Card className="card-paymentsummary mt-2">
              <Row
                className="d-flex tax-info-text"
                style={{ justifyContent: "space-between" }}
              >
                <Col>Subtotal (with Tax)</Col>
                <Col>
                  {" "}
                  ₹ {Number(editSubOrderData?.sub_total || 0).toFixed(2)}
                </Col>
                {/* <Col>₹{Number(finalTotal||0).toFixed(2)}</Col> */}
              </Row>
              <Row
                className="d-flex mt-2 tax-info-text"
                style={{ justifyContent: "space-between" }}
              >
                <Col>Discount</Col>
                <Col> ₹ {Number(discountAmount || 0).toFixed(2)}</Col>
              </Row>
              <Row
                className="d-flex mt-2 tax-info-text"
                style={{ justifyContent: "space-between" }}
              >
                <Col>Tax</Col>
                <Col> ₹ {(editSubOrderData?.tax || 0).toFixed(2)}</Col>
              </Row>
              <Divider />
              <Row
                className="d-flex "
                style={{ justifyContent: "space-between" }}
              >
                <Col className="deliveryBy-text">Total</Col>
                <Col className="deliveryBy-text">
                  ₹ {Number(editSubOrderData?.total || 0).toFixed(2)}
                </Col>
              </Row>
              <Row
                className="d-flex mt-2 tax-info-text"
                style={{ justifyContent: "space-between" }}
              >
                <Col>Paid</Col>
                <Col>₹ {Number(totalAmountPaid || 0).toFixed(2)}</Col>
              </Row>
              {editSubOrderData?.isMobCredit && editSubOrderData?.status==="Cancelled" &&(
            <Row
                className="d-flex tax-info-text mob-credit-splitup width-100 mt-4"
                style={{ justifyContent: "space-between" }}
              >
                <Col>mobCREDIT refund</Col>
                <Col className="text-right">
                  {" "}
                  ₹ {Number(mobCreditAmount || 0).toFixed(2)}<br/>
                 <span style={{fontSize:"10px"}}> Will be auto refunded to customer’s mobCREDIT in 45 mins</span>
                </Col>
               
              </Row>
            )}
             {editSubOrderData?.status==="Cancelled" ?(<>
              <Row
                className="d-flex tax-info-text mt-2"
                style={{ justifyContent: "space-between" }}
              >
                <Col className="deliveryBy-text">Customer refund by store:</Col>
                <Col className="deliveryBy-text">
                  
                  ₹ {Number(RefundAmount || 0).toFixed(2)}
                </Col>
               
              </Row>
             </>):(
              <>
              <Row
                className="d-flex mt-2 tax-info-text"
                style={{ justifyContent: "space-between" }}
              >
                <Col>Pending</Col>
                {totalAmountDue > 0 ? (
                  <Col className="deliveryBy-align-amount ">
                    <Text
                      className="text-danger"
                      style={{ marginRight: "4px" }}
                    >
                      Amount due{" "}
                    </Text>
                    <Text className="text-notpaid text-danger">
                      ₹ {Number(totalAmountDue || 0).toFixed(2)}
                    </Text>
                  </Col>
                ) : totalAmountDue < 0 ? (
                  <Col className="deliveryBy-align-amount text-success">
                    <Text
                      className="text-success"
                      style={{ marginRight: "4px" }}
                    >
                      Paid excess
                    </Text>{" "}
                    <Text
                      className="text-paid text-success"
                      style={{ marginLeft: "5px" }}
                    >
                      ₹ {Number(totalAmountDue || 0).toFixed(2)}
                    </Text>
                  </Col>
                ) : (
                  <Col className="deliveryBy-align-amount ">
                    <Text style={{ marginRight: "4px" }}></Text>
                    <Text className="text-refund">
                      ₹ {Number(totalAmountDue || 0).toFixed(2)}
                    </Text>
                  </Col>
                )}
              </Row>
              </>
            )}
              <Divider />
              <Row>
                <Col span={12}></Col>
                <Col
                  span={12}
                  className="text-right"
                  style={{
                    gap: "12px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Text
                    className="view-detail-payment"
                    onClick={() => setSummaryModalOpen(true)}
                  >
                    View all payments
                  </Text>
                  <Text
                    className="view-detail-payment"
                    onClick={() => handleStatus()}
                  >
                    Add payment
                  </Text>
                </Col>
              </Row>
              {editSubOrderData?.status!=="Cancelled" && totalAmountDue > 0 && (
                <Row
                  className="pending-status-card mt-4"
                  style={{ display: "flex", alignItems: "center", gap: "6px" }}
                >
                  <WarningIcon />
                  Payment is pending for this order. Please add the payment
                  details{" "}
                  <Text
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => handleStatus()}
                  >
                    {" "}
                    here
                  </Text>
                </Row>
              )}
            </Card>
          </>
        )}
      </Col>

      {isFetchingEditSubData ? (
        <>
          <Skeleton active paragraph={{ rows: 8 }} />
        </>
      ) : (
        <>
          <Col className="vehicle-notify">
          {!editSubOrderData?.is_store_order ?(
            <Text className="detail-heading">
              Delivery by:{" "}
              {editSubOrderData?.delivery_date &&
              dayjs(editSubOrderData?.delivery_date).format("DD MMM YYYY") &&
              editSubOrderData?.delivery_slot
                ? `${dayjs(editSubOrderData?.delivery_date).format(
                    "DD MMM YYYY"
                  )}, ${editSubOrderData?.delivery_slot}`
                : "No value"}
            </Text>
            ):(
              <Text className="detail-heading">
              Order details              
            </Text>
            )}
            <DeliveryTable
              data={editSubOrderData?.products}
              columns={columnsDeliveryBy}
              deliveryStatus={deliveryStatus}
              editSubOrderData={editSubOrderData}
            />
            
          </Col>
        </>
      )}
      <Col className="comp-wrapper" style={{justifyContent:"flex-end"}}>
      {!editSubOrderData?.is_store_order &&(
      <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              {deliveryStatus !== "Waiting" &&
                deliveryStatus !== "Cancelled" &&
                deliveryStatus !== "Rejected by seller" && (
                  <Button
                    className="detail-btn"
                    style={{
                      backgroundColor: "#01a685",
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "360px",
                    }}
                    type="primary"
                    icon={<FileIcon />}
                    onClick={handlePrintChallan}
                  >
                    Print delivery challan (pdf)
                  </Button>
                )}
            </Col>
      )}
            {deliveryStatus !== "Cancelled" && editSubOrderData?.is_store_order && (
              <Link
                to={`/Seller/StoreSales/update-order/${editSubData?.data?.store_order_id}`}
                target="_blank"
              >
                <Button className="view-detail-btn" icon={<EditIcon />}>
                  EDIT SALE
                </Button>
              </Link>
            )}
            
            {deliveryStatus === "Waiting" && (
              <Button
                className="detail-btn"
                style={{ color: "#bf2600", borderColor: "#bf2600" }}
                onClick={() => handleOpenRejectModal()}
              >
                REJECT
              </Button>
            )}
            {deliveryStatus !== "Cancelled" &&
              editSubOrderData?.is_store_order && (
                <Button
                  className="detail-btn"
                  style={{ color: "#bf2600", borderColor: "#bf2600" }}
                  onClick={() => handleOpenCancelModal()}
                >
                  CANCEL ORDER
                </Button>
              )}
            {deliveryStatus !== "Waiting" &&
              deliveryStatus !== "Delivered" &&
              deliveryStatus !== "Cancelled" &&
              deliveryStatus !== "Rejected by seller" && (
                <Button
                  className="detail-btn btn-primary"
                  // style={{ backgroundColor: "#0354a3" }}
                  type="primary"
                  onClick={handleConfirmationModal}
                >
                  {deliveryStatus === "Out for delivery"
                    ? "MARK DELIVERED"
                    : "ORDER SHIPPED"}
                </Button>
              )}
            {deliveryStatus === "Waiting" && (
              <Button
                className="detail-btn btn-primary"
                // style={{ backgroundColor: "#0354a3" }}
                type="primary"
                onClick={handleChooseVehicle}
                loading={isFetching}
              >
                ASSIGN VEHICLE TO ACCEPT
              </Button>
            )}
          </Col>
      {editSubOrderData?.customer_note && (
        <>
          <Col className="vehicle-notify">
            <Text className="detail-heading">Customer note</Text>
            <CustomerNote editSubOrderData={editSubOrderData} />
          </Col>
        </>
      )}
      {/* </Col> */}

      {editSubOrderData?.delivery_vehicles &&
        editSubOrderData?.delivery_vehicles.length > 0 && (
          <Col className="vehicle-notify">
            <Text className="detail-heading">
              {editSubOrderData?.delivery_vehicles.length || 0} Vehicle selected
            </Text>
          </Col>
        )}
      {editSubOrderData?.delivery_vehicles &&
        editSubOrderData?.delivery_vehicles.length > 0 && (
          <Col className="vehicle-notify">
            <SelectedVehicleTable
              className="mob-list-table no-right-border"
              tableDataSource={editSubOrderData?.delivery_vehicles}
              tableColumns={columns}
              pagination={false}
            />
          </Col>
        )}
      <Col className="text-left d-block d-sm-block d-lg-none d-xl-none">
        <Text>
          In case of any queries <br />
          Call support: <a href={`tel:+91 8660423608`}>+91 8660423608</a>
        </Text>
      </Col>
      {/* <AddVehicleModal
          setIsModalOpen={setAddVehicleModalOpen}
          isModalOpen={addVehicleModalOpen}
          title="Add vehicle"
          vehicleDetailData={vehicleData?.data}
          addVehicleFormData={addVehicleFormData}
          setAddVehicleFormData={setAddVehicleFormData}
          editSubData={editSubData}
          addVehicleApi={addVehicleApi}
          datePikerEmpty={datePikerEmpty}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
        />
        <AddVehicleModal
          setIsModalOpen={setUpdateVehicleModalOpen}
          isModalOpen={updateVehicleModalOpen}
          title="Update vehicle"
          vehicleDetailData={vehicleData?.data}
          addVehicleFormData={addVehicleFormData}
          setAddVehicleFormData={setAddVehicleFormData}
          editSubData={editSubData}
          addVehicleApi={updateVehicleApi}
          vehicleUpdateData={vehicleUpdateData}
          datePikerEmpty={datePikerEmpty}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
        />
         */}
      <SellerChooseVehicleModal
        handleOk={() => handleAcceptOrderStatus("Ready for Pickup")}
        setIsModalOpen={setChooseVehicleModalOpen}
        isModalOpen={ChooseVehicleModalOpen}
        vendorAddressData={vendorAddressData}
        pickupAddressValue={pickupAddressValue}
        vendorAddressapiSuccess={isFetching}
        setPickupAddressValue={setPickupAddressValue}
        updateOrderStatusLoading={updateOrderVehicleRequestLoading}
        DeliveryDateValue={DeliverydateValue}
        SelectedTime={selectedTime}
        setDeliveryDateValue={setDeliveryDateValue}
        setSelectedTime={setSelectedTime}
        vehiclesmaster={editSubOrderData?.vehicles}
        addVehicleFormData={addVehicleFormData}
        setAddVehicleFormData={setAddVehicleFormData}
      />
      {/* <ActivityLogSidebar
          drawerVisible={drawerVisible}
          setDrawerVisible={setDrawerVisible}
        /> */}
      <ManageDeleteModal
        handleOk={handleDeleteModalCancel}
        open={isDeleteModalOpen}
        handleSave={onDelete}
        data={`this vehicle ${productTableData?.vechicle_number}`}
      />
      <SellerConfirmationModal
        title={
          deliveryStatus === "Out for delivery"
            ? "Mark Delivered"
            : "Order Shipped"
        }
        updateOrderStatusLoading={updateOrderStatusLoading}
        subId={editSubData?.data?.sub_order_id}
        isModalOpen={ConfirmationModalOpen}
        setIsModalOpen={setConfirmationModalOpen}
        data={editSubData?.data}
        handleOk={() =>
          handleChangeStatus(
            deliveryStatus === "Out for delivery"
              ? "Delivered"
              : "Out for delivery"
          )
        }
      />
      <SellerCancelOrderModal
        // title={"Are you sure?"}
        title={"CANCEL ORDER - Are you sure?"}
        cancelText={"NO, GO BACK"}
        saveText={"YES, CANCEL ORDER"}
        open={openCancelModal}
        handleCloseModal={handleCloseCancelModal}
        handleCancel={handleCloseCancelModal}
        handleSave={handleCancelOrderModal}
        data={editSubData?.data}
        checkboxState={checkboxState}
        setCheckboxState={setCheckboxState}
        totalAmountPaid={totalAmountPaid}
      />

      <SellerRejectOrderModal
              // title={"Are you sure?"}
        title={"Are you sure you want to reject the order?"}
        cancelText={"CANCEL"}
        saveText={"REJECT ORDER"}
        open={openRejectModal}
        handleCloseModal={handleRejectCancelModal}
        handleCancel={handleRejectCancelModal}
        handleSave={handleRejectOrderModal}
        data={editSubData?.data}
        checkboxState={checkboxState}
        setCheckboxState={setCheckboxState}
      />
      <AddDirectPaymentModal
        openAddFile={openUploadModal}
        setOpenAddFile={setOpenUploadModal}
        titleText={titleText}
        custData={editSubOrderData?.cutomer}
        handleOk={handleOk}
        formData={formData}
        setFormData={setFormData}
        datePickerEmpty={datePikerEmpty}
        setDatePickerEmpty={setDatePickerEmpty}
        paymentMode={paymentMode}
        setPaymentMode={setPaymentMode}
        editPayment={editPayment}
        setEditPayment={setEditPayment}
        setOpenUploadModal={setOpenUploadModal}
        setInputDate={setInputDate}
        inputDate={inputDate}
        setAmountError={setAmountError}
        amountError={amountError}
        dateError={dateError}
        setDateError={setDateError}
        editOrderVal={editOrderVal}
        orderNewPaymentApi={orderNewPaymentApi}
        unmapped_amount={editSubOrderData?.unmapped_amount}
        getSubOrderDetailApi={getSubOrderDetailApi}
        pendingAmount={editOrderVal?.pending_amount}
      />
      <Modal
        title="Payment summary"
        open={summaryModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="styled-summary-modal"
      >
        <Col className="modal-table-wrapper">
          {editSubOrderData?.payments?.length > 0 ? (
            <Table
              pagination={false}
              columns={paymentsummarycolumns}
              dataSource={editSubOrderData?.payments}
              className="styled-table custom-font"
              scroll={{ y: 240 }}
            />
          ) : (
            <Col
              style={{ textAlign: "center", fontSize: "16px", fontWeight: 500 }}
            >
              NO DATA FOUND !
            </Col>
          )}
        </Col>
      </Modal>
    </Col>
  );
};

export default SellerViewDetails;
